<template>
  <div class="manage">
    <el-form
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
      class="demo-dynamic"
    >
      <el-form-item
        prop="email"
        label="邮箱"
        :rules="[
          { message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <div class="email">{{ Email }}</div>
        <el-input
          v-model="dynamicValidateForm.email"
          placeholder="请输入新邮箱"
        ></el-input>
        <el-input
          placeholder="请输入密码"
          v-model="dynamicValidateForm.password"
          show-password
          :readonly="passwordReadonly"
          @focus="passwordFocus"
          @blur="passwordBlur"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="button"
          type="primary"
          @click="submitEmailForm('dynamicValidateForm')"
          :disabled="!canEditEmail"
          >确认修改</el-button
        >
      </el-form-item>
    </el-form>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <div class="pas">
        <span class="text-pass">密码</span>
        <el-form-item prop="oldPwd">
          <el-input
            placeholder="请输入密码"
            type="password"
            v-model="ruleForm.oldPwd"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item prop="newPwd">
        <el-input
          placeholder="请输入新密码"
          type="password"
          v-model="ruleForm.newPwd"
          autocomplete="off"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item prop="checkNewPwd">
        <el-input
          placeholder="请再次输入新密码"
          type="password"
          v-model="ruleForm.checkNewPwd"
          autocomplete="off"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="button"
          type="primary"
          @click="submitForm('ruleForm')"
          :disabled="!canEditPwd"
          >确认修改</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { instance, ajax } from "@/api/index";
import Cookies from "js-cookie";

export default {
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.ruleForm.checkNewPwd !== "") {
          this.$refs.ruleForm.validateField("checkNewPwd");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      Email: "",
      dynamicValidateForm: {
        email: "",
        password: "",
      },

      input: "",
      ruleForm: {
        oldPwd: "",
        newPwd: "",
        checkNewPwd: "",
      },
      rules: {
        oldPwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        newPwd: [{ validator: validatePass1, trigger: "blur" }],
        checkNewPwd: [{ validator: validatePass2, trigger: "blur" }],
      },
      passwordReadonly: true,
    };
  },
  created() {
    this.Email = Cookies.get("email");
    if (!this.Email) {
      window.location.href = "/login";
    }
  },
  computed: {
    canEditEmail() {
      return (
        this.dynamicValidateForm.password && this.dynamicValidateForm.email
      );
    },
    canEditPwd() {
      return (
        this.ruleForm.checkNewPwd &&
        this.ruleForm.newPwd &&
        this.ruleForm.oldPwd
      );
    },
  },
  methods: {
    // 修改密码
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          ajax(
            "/Manager/ChangePwd",
            {
              // username: sessionStorage.getItem("Username"),
              oldPwd: this.$md5(this.ruleForm.oldPwd),
              newPwd: this.$md5(this.ruleForm.newPwd),
            },
            "POST"
          )
            .then((resp) => {
              if (resp.data.result) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.ruleForm.oldPwd = "";
                this.ruleForm.newPwd = "";
                this.ruleForm.checkNewPwd = "";
              }
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: "请求失败，请重试!",
              });
              console.log(error)
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 修改邮箱
    submitEmailForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate(async (valid) => {
        if (valid) {
          ajax(
            "/Manager/ChangeEmail",
            {
              email: this.dynamicValidateForm.email,
              password: this.$md5(this.dynamicValidateForm.password),
            },
            "POST"
          )
            .then((res) => {
              if (res.data.result) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.Email = this.dynamicValidateForm.email;
                Cookies.set(this.dynamicValidateForm.email);
                this.dynamicValidateForm.email = "";
                this.dynamicValidateForm.password = "";
              }
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: "请求失败，请重试!",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    passwordFocus() {
      this.passwordReadonly = false;
    },
    passwordBlur() {
      this.passwordReadonly = true;
    },
  },
};
</script>

<style scoped>
.manage {
  position: relative;
  height: 100%;
  min-height: 1000px;
  background: #ffffff;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 0px;
  margin-top: 10px;
  margin-left: 10px;
}
.text-pass {
  margin-left: 113px;
}

.manage .button {
  width: 520px;
  height: 38px;
  background: #1fa1a0;
  opacity: 1;
  border-radius: 5px;
  border: 0px;
  margin-top: 30px;
}

.text-pass {
  text-align: right;
  vertical-align: middle;
  font-size: 22px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-family: PingFang SC;
}

.demo-dynamic >>> .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  margin-top: 34px;
  font-size: 22px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-family: PingFang SC;
}

.manage >>> input {
  width: 520px;
  height: 38px;
  background: #f3f3f3;
  border: 1px solid #cbcbcb;
  opacity: 1;
  border-radius: 5px;
}

.manage >>> input,
.manage >>> button {
  margin: 10px;
  margin-left: 209px;
}
.manage .el-form-item {
  margin-left: 113px;
}
.manage >>> .el-form-item {
  margin-bottom: 0px;
}
.email {
  position: absolute;
  width: 144px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #333333;
  opacity: 1;
  left: 210px;
  top: 60px;
}
</style>
