import axios from 'axios'  // 引入axios模块
import { Message, MessageBox } from 'element-ui';
import Cookies from "js-cookie";
import router from '@/router'


// axios.defaults.timeout = 10000;   // 配置ajax请求的超时时间
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; // 配置ajax post请求的请求头
// 创建实例时配置默认值
export const instance = axios.create({
    baseURL: "/".replace(/\/+$/, process.env.VUE_APP_API_ENDPOINT),
    timeout: 10000,
    headers: {
        'Authorization': `Bearer ${Cookies.get('token') || ''}`
    }
});

// 配置响应拦截器
instance.interceptors.response.use((response) => {
    if (response.data && !(response?.data?.success)) {
        // 意料之内的错误信息
        // console.log("response.data.errorMsg",response.data)
        Message.info(response.data.errorMsg);
        // 允许后续业务接管
        // throw new Error('异常信息')
        return response
    }

    return response;
}, (error) => {
    var response = error.response
    // NOTE:promise 中 messagebox 有问题
    switch (response?.status) {
        default:
        case 500:
        case 425:
            // 意料之外错误信息
            Message.info({
                message: '系统异常！',
                duration: 3000,
                showClose: true
            })
            throw new Error(`系统异常！${response?.data?.errorMsg}`)
            break;

        case 400:
            var data = response?.data
            var dKeys = Object.keys(data?.errors)
            var dValues = dKeys.map(k => data.errors[k].join("<br>"))

            var fullErrorMsg = dValues.join('<br> ')
            var eMsg = data?.errorMsg || fullErrorMsg
            Message.warning({
                dangerouslyUseHTMLString: true,
                message: eMsg,
                showClose: true
            })
            break;

        case 401:
            // 需要登录
            Message.info({
                message: '请登录后重试！',
                duration: 3000,
                onClose: ToLogin,
                showClose: true
            })
            throw new Error('请登录后重试！')
            break;
        case 403:
            // 权限不足
            Message.info({
                message: '当前账号无访问权限！',
                duration: 3000,
                showClose: true
            })
            throw new Error('当前账号无访问权限！')
            break;
        case 404:
            // NOTE:go home
            window.location.href = "/"
            break;
    }

});

const ToLogin = params => {
    // NOTE：清除用户信息
    localStorage.removeItem("Username");
    localStorage.removeItem("Password");
    localStorage.removeItem("checked");

    Cookies.remove("token");
    Cookies.remove("email");
    window.sessionStorage.removeItem("token");

    Cookies.remove('user')
    router.replace({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath }
    });
    window.location.reload()
};

// 声明一个方法，ajax，后面只要发请求，那么就需要调用ajax方法
// ajax("/manager/api/auth/admin/login",{username,password},'post')
// 发ajax请求，就是调用ajax方法，调用ajax方法，返回promise
// ajax("/manager/api/auth/admin/login",{username,password},'post').then(value=>).catch(err=>)
export function ajax (url = '', params = {}, type = 'GET') {
    if (type.toUpperCase() === 'GET') {
        return instance({
            url,
            params
        })
    } else if (type.toUpperCase() === 'POST') {
        return instance({
            method: 'post',
            url,
            data: params
        })
    } else if (type.toUpperCase() === 'DELETE') {
        return instance({
            method: 'delete',
            url,
            params
        })
    }
}

export default {
    instance: instance,
    ajax: ajax
}
