<template>
  <div class="home">
    <div class="s">
      <el-radio-group v-model="radio" @input="changeShow">
        <el-radio-button label="Pv"></el-radio-button>
        <el-radio-button label="Task"></el-radio-button>
      </el-radio-group>

      <div class="pv" v-show="ifShow == true">
        <div class="head">
          <div class="day">
            <p>当天浏览量</p>
            <div>{{ pvDay }}</div>
          </div>
          <div class="seven">
            <p>7天浏览量</p>
            <div>{{ pvSeven }}</div>
          </div>
          <div class="thirty">
            <p>30天浏览量</p>
            <div>{{ pvThirty }}</div>
          </div>
          <div class="all">
            <p>总浏览量</p>
            <div>{{ pvAll }}</div>
          </div>
        </div>
        <div class="wrap">
          <div class="changeDay">
            <el-button @click="changePvDay('sevenDays')">7 days</el-button>
            <el-button @click="changePvDay('thirtyDays')">30 days</el-button>
            <el-button @click="changePvDay('allDays')">all days</el-button>
          </div>
          <div id="pvBar"></div>
        </div>
      </div>
      <div class="task" v-show="ifShow == false">
        <div class="head">
          <div class="day">
            <p>当天任务量</p>
            <div>{{ day }}</div>
          </div>
          <div class="seven">
            <p>7天任务量</p>
            <div>{{ seven }}</div>
          </div>
          <div class="thirty">
            <p>30天任务量</p>
            <div>{{ thirty }}</div>
          </div>
          <div class="all">
            <p>总任务量</p>
            <div>{{ all }}</div>
          </div>
        </div>
        <el-radio-group v-model="geneShow" @input="changeTaskShow">
          <el-radio-button label="task"></el-radio-button>
          <el-radio-button label="gene"></el-radio-button>
        </el-radio-group>
        <div class="wrap" v-show="ifGeneShow == false">
          <div class="changeDay">
            <el-button @click="changeDay('sevenDays')">7 days</el-button>
            <el-button @click="changeDay('thirtyDays')">30 days</el-button>
            <el-button @click="changeDay('allDays')">all days</el-button>
          </div>
          <div id="bar"></div>
        </div>
        <div class="wrap" v-show="ifGeneShow == true">
          <div class="changeDay">
            <el-button @click="changeGeneDay('sevenDays')">7 days</el-button>
            <el-button @click="changeGeneDay('thirtyDays')">30 days</el-button>
            <el-button @click="changeGeneDay('allDays')">all days</el-button>
          </div>
          <div id="geneBar"></div>
        </div>
      </div>
    </div>
    <div class="drawWrap">
      <div id="pie"></div>
    </div>
  </div>
</template>

<script>
import { instance, ajax } from "@/api/index";

export default {
  data() {
    return {
      day: "",
      seven: "",
      thirty: "",
      all: "",
      pvDay: 0,
      pvSeven: 0,
      pvThirty: 0,
      pvAll: 0,
      drawPieData: [],
      drawBarAllData: [],
      drawBarThirtyData: [],
      drawBarSevenData: [],
      drawPvBarAllData: [],
      drawPvBarThirtyData: [],
      drawPvBarSevenData: [],
      drawGeneBarAllData: [],
      drawGeneBarThirtyData: [],
      drawGeneBarSevenData: [],
      ifShow: true,
      radio: "Pv",
      geneShow: "task",
      ifGeneShow: false
    };
  },
  mounted() {
    this.getStatistics();
    this.getDrawPieData()
    this.getDrawPVBarData()
    // this.getGeneTopData()
  },
  methods: {
    getGeneTopData() {
      // GeneStatistics
      ajax("/Statistics/GeneStatistics").then((resp) => {
        // console.log("GeneStatistics", resp.data.result.records)
        for (var i = 0; i < resp.data.result.records.length; i++) {
          if (resp.data.result.records[i].type == 1) {
            this.drawGeneBarAllData.push(resp.data.result.records[i].statistics);
          }
          if (resp.data.result.records[i].type == 2) {
            this.drawGeneBarThirtyData.push(resp.data.result.records[i].statistics);
          }
          if (resp.data.result.records[i].type == 3) {
            this.drawGeneBarSevenData.push(resp.data.result.records[i].statistics);
          }
        }
        this.drawGeneBar(this.drawGeneBarAllData)
        // console.log("this.drawGeneBarAllData", this.drawGeneBarThirtyData)
      })
    },
    drawGeneBar(data) {
      // console.log(data)
      data[0] = data[0].filter(item => item.gene !== null);
      // console.log(data);
      var res = (data[0].sort((a, b) => {
        return b.total - a.total
      })).slice(0, 10)
      var result = {}
      res.map(itm => {
        result[itm.gene] = itm.total
      })
      // console.log(res,result)
      var myChart = this.$echarts.init(document.getElementById("geneBar"));
      const option = {
        title: {
          text: "Genes stat(top10)",
        },
        grid: {
          left: "3%",
          right: "6%",
          bottom: "11%",
          containLabel: true,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                let tdHeaders = `
                                <tr>
                                    <th style = "padding: 10px 10px">基因名</th>
                                    <th style = "padding: 10px 10px">数量</th>
                                </tr> 
                            `;
                let table = `<table border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center"><tbody><tr>${tdHeaders} </tr>`;
                var tdBodys = ""; //数据
                for (let i = 0, l = axisData.length; i < l; i++) {
                  for (let j = 0; j < series.length; j++) {
                    tdBodys += "<td>" + series[j].data[i] + "</td>"; //组装表数据
                  }
                  table +=
                    '<tr><td style="padding: 10px 10px">' +
                    axisData[i] +
                    "</td>" +
                    tdBodys +
                    "</tr>";
                  tdBodys = "";
                }
                table += "</tbody></table></div>";
                return table;
              },
            },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider'
          }
        ],
        xAxis: {
          data: Object.keys(result),
          axisLabel: {
            interval: 0,
            rotate: 45, // 主要是这个 设置角度即可 - 90 ~ 90 旋转方向也不同
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            // name: "访问次数",
            data: Object.values(result),
            type: "bar",
            label: {
              show: true,
              position: 'top'
            },
          },
        ],
      };
      myChart.setOption(option, true);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    changeShow() {
      // console.log(this.radio)
      if (this.radio == "Pv") {
        this.ifShow = true
      } else {
        this.getDrawBarData()
        this.ifShow = false
      }
    },
    changeTaskShow() {
      // console.log(this.radio)
      if (this.geneShow == "task") {
        this.ifGeneShow = false
        // this.drawBar()
      } else {
        // console.log("gggeeee")
        this.ifGeneShow = true
        this.getGeneTopData()
      }
    },
    getStatistics() {
      ajax("/Manager/AccessLogStatistics").then((resp) => {
        this.day = resp.data.result.today;
        this.seven = resp.data.result.sevenDays;
        this.thirty = resp.data.result.thirtyDays;
        this.all = resp.data.result.total;
      });
    },
    changeDay(days) {
      // console.log(days)
      if (days == "allDays") {
        this.drawBar(this.drawBarAllData[0]);
      } else if (days == "thirtyDays") {
        this.drawBar(this.drawBarThirtyData[0]);
      } else {
        this.drawBar(this.drawBarSevenData[0]);
      }
    },
    changePvDay(days) {
      // console.log(days)
      if (days == "allDays") {
        this.drawPvBar(this.drawPvBarAllData[0]);
      } else if (days == "thirtyDays") {
        this.drawPvBar(this.drawPvBarThirtyData[0]);
      } else {
        this.drawPvBar(this.drawPvBarSevenData[0]);
      }
    },
    changeGeneDay(days) {
      // console.log(days)
      if (days == "allDays") {
        this.drawGeneBar(this.drawGeneBarAllData);
      } else if (days == "thirtyDays") {
        this.drawGeneBar(this.drawGeneBarThirtyData);
      } else {
        this.drawGeneBar(this.drawGeneBarSevenData);
      }
    },
    getDrawPieData() {
      var ipCount = {};
      ajax("/Statistics/regionUserStatistics").then((resp) => {
        // console.log("test", resp);
        var records = resp.data.result.records
        // console.log(records)
        // 遍历记录数组，统计相同IP地址的用户数量
        for (var i = 0; i < records.length; i++) {
          var ip = records[i].area;
          // console.log(ip)
          if (ipCount[ip]) {
            ipCount[ip]++;
          } else {
            ipCount[ip] = 1;
          }
        }
        for (var ip in ipCount) {
          this.drawPieData.push({ name: ip, value: ipCount[ip] });
        }
        this.drawPie(this.drawPieData)
        // console.log("sssssss", ipCount, this.drawPieData)
      }).catch((error) => {
        this.$message({
          type: "error",
          message: "请求失败，请重试IPTest!",
        });
      });
    },
    drawPie(data) {
      // console.log("drawddddd", data, this.drawPieData);
      var myChart = this.$echarts.init(document.getElementById("pie"));
      const option = {
        title: {
          text: "RegionUserStatistics",
          top: "20px",
          textStyle: {
            fontSize: 25,
          }
        },
        tooltip: {
          trigger: "item",
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              optionToContent: function (opt) {
                let series = opt.series[0].data; // 需要的数据源
                // 表头
                let thead = `
                                <tr>
                                    <th style = "padding: 10px 10px">地区</th>
                                    <th style = "padding: 10px 10px">数量</th>
                                </tr> 
                            `;
                // 表身
                let tbody = "";
                series.forEach(function (item) {
                  tbody += `
                                 <tr>
                                    <td style="padding: 10px 10px">
                                            ${item.name}
                                    </td>
                                    <td style="padding: 10px 10px">
                                            ${item.value}
                                    </td>
                                 </tr>
                           `;
                });
                // 表格
                let table = `
                <table border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center">
                                ${thead}${tbody}
                            `;
                return table;
              },
            },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          top: "12%",
          // orient: "vertical",
          left: "center",
        },
        series: [
          {
            // name: "地区",
            type: "pie",
            // 饼图大小 小圈 大圈
            radius: ["45%", "65%"],
            // 饼图位置 左右 上下
            center: ["50%", "60%"],
            data: data,
          },
        ],
      };
      // console.log("pieOption",option);
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getDrawBarData() {
      ajax("/Statistics/TaskStatistics").then((resp) => {
        // console.log("taskresp", resp.data.result.records);
        for (var i = 0; i < resp.data.result.records.length; i++) {
          if (resp.data.result.records[i].type == 1) {
            this.drawBarAllData.push(resp.data.result.records[i].statistics);
          }
          if (resp.data.result.records[i].type == 2) {
            this.drawBarThirtyData.push(resp.data.result.records[i].statistics);
          }
          if (resp.data.result.records[i].type == 3) {
            this.drawBarSevenData.push(resp.data.result.records[i].statistics);
          }
        }
        this.drawBar(this.drawBarAllData[0]);

        // console.log("this.drawBarAllData", this.drawBarAllData[0]);
      })
    },
    drawBar(data) {
      var res = data.reduce((acc, item) => {
        acc[item.country] = item.total;
        return acc;
      }, {});
      var result = Object.entries(res)
        .sort((a, b) => b[1] - a[1]) // 根据值进行降序排序  
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
      // console.log(result)
      var myChart = this.$echarts.init(document.getElementById("bar"));
      const option = {
        title: {
          text: "Tasks stat",
        },
        grid: {
          left: "3%",
          right: "6%",
          bottom: "11%",
          containLabel: true,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                let tdHeaders = `
                                <tr>
                                    <th style = "padding: 10px 10px">地区</th>
                                    <th style = "padding: 10px 10px">数量</th>
                                </tr> 
                            `;
                let table = `<table border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center"><tbody><tr>${tdHeaders} </tr>`;
                var tdBodys = ""; //数据
                for (let i = 0, l = axisData.length; i < l; i++) {
                  for (let j = 0; j < series.length; j++) {
                    tdBodys += "<td>" + series[j].data[i] + "</td>"; //组装表数据
                  }
                  table +=
                    '<tr><td style="padding: 10px 10px">' +
                    axisData[i] +
                    "</td>" +
                    tdBodys +
                    "</tr>";
                  tdBodys = "";
                }
                table += "</tbody></table></div>";
                return table;
              },
            },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider'
          }
        ],
        xAxis: {
          data: Object.keys(result),
          axisLabel: {
            interval: 0,
            rotate: 45, // 主要是这个 设置角度即可 - 90 ~ 90 旋转方向也不同
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            // name: "访问次数",
            data: Object.values(result),
            type: "bar",
            label: {
              show: true,
              position: 'top'
            },
          },
        ],
      };
      myChart.setOption(option, true);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getDrawPVBarData() {
      ajax("/Statistics/VisitStatistics").then((resp) => {
        // console.log("visitttttt", resp)
        for (var i = 0; i < resp.data.result.records.length; i++) {
          if (resp.data.result.records[i].type == 1) {
            this.drawPvBarAllData.push(resp.data.result.records[i].statistics);
          }
          if (resp.data.result.records[i].type == 2) {
            this.drawPvBarThirtyData.push(resp.data.result.records[i].statistics);
          }
          if (resp.data.result.records[i].type == 3) {
            this.drawPvBarSevenData.push(resp.data.result.records[i].statistics);
          }
        }
        // this.pvAll=0
        // console.log("drawPvBarAllData", this.drawPvBarAllData)
        this.drawPvBarAllData[0].forEach(item => this.pvAll += item.total);
        this.drawPvBarThirtyData[0].forEach(item => this.pvThirty += item.total);
        this.drawPvBarSevenData[0].forEach(item => this.pvSeven += item.total);
        this.pvDay = resp.data.result.today
        // console.log(this.pvAll,this.pvThirty,this.pvSeven)
        this.drawPvBar(this.drawPvBarAllData[0]);
      })
    },
    drawPvBar(data) {
      // console.log("drawPvBar", data)
      var res = data.reduce((acc, item) => {
        if (item.country == "Hong Kong") {
          item.country = "China"
        }
        if (acc[item.country]) {
          acc[item.country] += item.total;
        } else {
          acc[item.country] = item.total;
        }
        return acc;
      }, {});

      var result = Object.entries(res)
        .sort((a, b) => b[1] - a[1]) // 根据值进行降序排序  
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}); // 将排序后的键值对转换回对象
      // console.log("result", result)
      var myChart = this.$echarts.init(document.getElementById("pvBar"));
      const option = {
        title: {
          text: "Visits stat",
        },
        grid: {
          left: "3%",
          right: "6%",
          bottom: "11%",
          containLabel: true,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                let tdHeaders = `
                                <tr>
                                    <th style = "padding: 10px 10px">地区</th>
                                    <th style = "padding: 10px 10px">数量</th>
                                </tr> 
                            `;
                let table = `<table border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center"><tbody><tr>${tdHeaders} </tr>`;
                var tdBodys = ""; //数据
                for (let i = 0, l = axisData.length; i < l; i++) {
                  for (let j = 0; j < series.length; j++) {
                    tdBodys += "<td>" + series[j].data[i] + "</td>"; //组装表数据
                  }
                  table +=
                    '<tr><td style="padding: 10px 10px">' +
                    axisData[i] +
                    "</td>" +
                    tdBodys +
                    "</tr>";
                  tdBodys = "";
                }
                table += "</tbody></table></div>";
                return table;
              },
            },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider'
          }
        ],
        xAxis: {
          data: Object.keys(result),
          axisLabel: {
            interval: 0,
            rotate: 45, // 主要是这个 设置角度即可 - 90 ~ 90 旋转方向也不同
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            // name: "访问次数",
            data: Object.values(result),
            type: "bar",
            label: {
              show: true,
              position: 'top'
            },
          },
        ],
      };
      myChart.setOption(option, true);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  }
};
</script>

<style scoped>
::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #1fa1a0;
  border-color: #038989;
}

.home {
  position: relative;
  border-radius: 0px;
  margin-top: 10px;
  margin-left: 10px;
}

.pv,
.task {
  /* border: 1px solid #dbdbdb; */
  box-sizing: border-box;
  /*必须设置border宽度和样式*/
  border: 1px solid;
  /*设置线性渐变*/
  border-image: linear-gradient(180deg, #1fa1a0, #ffff) 2 2;
  margin-top: -1px;
  border-bottom: 0px;
}

.head {
  display: flex;

  border-bottom: 0px;
  /* background-color: #fff; */
  margin-bottom: 30px;

}

.head p {
  margin-top: 5px;
}


.s {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 20px;
}

.task .el-radio-group {
  margin-left: calc(50% - 120px);
  margin-bottom: 20px;
}

#pvBar,
#bar,
#geneBar {
  width: 100%;
  height: 450px;
  margin-top: 15px;
}



.drawWrap {
  background: #ffffff;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 0px;
  padding: 10px;
}

#pie {
  width: 100%;
  height: 450px;
}

.day,
.seven,
.thirty {
  height: 58px;
  opacity: 1;
  position: relative;
}

.day:after,
.seven:after,
.thirty:after {
  content: "";
  position: absolute;
  right: 0;
  top: 15px;
  height: 70px;
  width: 1px;
  background-color: rgb(206, 206, 206);
}

.day,
.seven,
.thirty,
.all {
  width: 198px;
  padding: 20px;
}

.changeDay {
  margin-left: calc(50% - 190px);
}

.changeDay button {
  width: 120px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  border-radius: 15px;
}

::v-deep .el-radio-button__inner {
  width: 120px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  border-radius: 0px !important;
  border-bottom: 1px solid #1fa1a0;
}
</style>
