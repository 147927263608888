<template>
  <div class="login-wrapper">
    <el-form :model="user" :rules="rules" ref="logoForm" class="login-form">
      <h1>登录</h1>
      <el-form-item prop="Username">
        <el-input
          class="name"
          type="text"
          v-model="user.Username"
          placeholder="Username"
        ></el-input>
      </el-form-item>
      <el-form-item prop="Password">
        <el-input
          type="password"
          v-model="user.Password"
          placeholder="Password"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox class="check" v-model="user.checked">记住密码</el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleLogin" class="login_btn"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { instance, ajax } from "@/api/index";
// import { AxiosHelper } from "@/api";

import Cookies from "js-cookie";
export default {
  data() {
    return {
      user: {
        Username: "",
        Password: "",
        checked: false,
      },
      rules: {
        // 写校验规则
        Username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        Password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    let checked = JSON.parse(localStorage.getItem("checked"));
    // console.log("checked:",checked);
    this.user.checked = checked;

    this.user.Username = localStorage.getItem("Username");
    this.user.Password = localStorage.getItem("Password");
  },
  methods: {
    handleLogin(formName) {
      console.log("点击了登录....");
      var _this = this;
      this.$refs.logoForm.validate(async (valid) => {
        // console.log(valid); // false表示校验没有通过  true表示校验通过
        if (valid) {
          // alert('校验通过!');
          let resp = await ajax(
            "/Manager/Login",
            {
              Username: this.user.Username,
              Password: this.$md5(this.user.Password),
            },
            "GET"
          );

          {
            // 登录成功了
            // 将用户名保存到sessionStorage中
            sessionStorage.setItem("Username", _this.user.Username);
            var token = resp.data.result.token;
            window.sessionStorage.setItem("token", token);
            Cookies.set("userName", _this.user.Username);
            Cookies.set("token", token);
            Cookies.set("email", resp.data.result.email);
            instance.defaults.headers["Authorization"] = `Bearer ${token}`;
            // console.log("==>",this.user.checked);
            if (_this.user.checked) {
              // 表示你勾选了记住密码
              localStorage.setItem("Username", _this.user.Username);
              localStorage.setItem("Password", _this.user.Password);
              localStorage.setItem("checked", _this.user.checked);
            } else {
              // 没有勾选
              localStorage.removeItem("Username");
              localStorage.removeItem("Password");
              localStorage.removeItem("checked");
            }

            _this.$message({
              message: "登录成功!",
              type: "success",
            });

            // 跳到后台首页面（Log日志管理页面）
            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect });
            } else {
              this.$router.push({ path: "/home" });
            }
            window.location.reload();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  background-color: #fff;
  padding: 5px 10px;
  width: 400px;
  height: 336px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.name {
  margin-top: 80px;
}
.login-wrapper >>> .el-input__inner {
  width: 340px;
  height: 45px;
  background: #f3f3f3;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 5px;
  margin-top: 10px;
}
h1 {
  position: absolute;
  width: 100%;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 0px;
  color: #1FA1A0;
  opacity: 1;
  top: 40px;
  left: 0px;
}

.el-button >>> span {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}

.login_btn {
  position: absolute;
  width: 156px;
  height: 45px;
  background: #1FA1A0;
  opacity: 1;
  border-radius: 5px;
  bottom: -45px;
  left: 132px;
}

.login_btn:hover {
  background-color: #084b66;
}
.el-button--primary {
  border: none;
}
.check {
  position: absolute;
  left: 30px;
  bottom: -25px;
}

.el-checkbox,
.is-checked {
  color: #084b66;
}
</style>
