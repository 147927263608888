<template>
  <el-header class="nav">
    <div class="wrapper">
      <div class="logo">
        <img src="../../public/logo.png" alt="" />
      </div>
      <user-info class="userInfo"></user-info>
    </div>
  </el-header>
</template>

<script>
import UserInfo from "../components/UserInfo.vue";
export default {
  components: {
    UserInfo,
  },
  methods: {
    logout() {
      sessionStorage.removeItem("Username");
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.nav {
  width: 100%;
  height: 80px !important;
  background: #1fa1a0;
  opacity: 1;
  border-radius: 0px;
  display: table;
}
.logo {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.wrapper {
  display: table;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.userInfo {
  margin-top: 10px;
  z-index: 9999;
}
</style>