<template>
  <div class="cas">
    <!-- Cas-target部分 -->
    <div class="cell">
      <!-- 细胞类型 -->
      <span>Cell type</span>
      <!-- 细胞添加 -->
      <img
        class="add"
        src="../../public/add1.png"
        @click="handleAdd(3, 1)"
        title="添加"
      />
      <!-- 细胞添加弹出框 -->
      <el-dialog
        title="添加"
        class="dialog"
        :visible.sync="dialogFormVisibleAdd"
        :close-on-click-modal="false"
      >
        <el-input
          v-model="addParameterName"
          placeholder="Parameter name"
        ></el-input>
        <el-input
          v-model="addParameterValue"
          placeholder="Parameter value"
        ></el-input>
        <div class="checkbox-bar">
          <el-checkbox
            label="是否包括23bp序列结果"
            class="result"
            v-model="addUse23BP"
            v-show="false"
          ></el-checkbox>
          <el-checkbox
            label="设置为默认"
            class="default"
            v-model="addIsDefault"
          ></el-checkbox>
        </div>
        <div slot="footer" class="dialog-footer-add">
          <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
          <el-button type="primary" @click="paramAdd()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-row
      :gutter="20"
      style="
        margin-left: 67px;
        margin-right: 0px;
        margin-bottom: 5px;
        margin-top: 10px;
      "
    >
      <el-col :span="8"> Parameter name </el-col>
      <el-col :span="8"> Parameter value </el-col>
      <el-col :span="8"> </el-col>
    </el-row>
    <el-row
      :gutter="20"
      v-for="item in CasTargetCellTableData"
      :key="item.configId"
      style="margin-left: 67px; margin-right: 0px; margin-bottom: 20px"
    >
      <el-col :span="8">
        <el-input :disabled="true" v-model="item.name"></el-input>
      </el-col>
      <el-col :span="8">
        <el-input :disabled="true" v-model="item.value"></el-input>
      </el-col>
      <el-col :span="8">
        <!-- 细胞删除 -->
        <img
          src="../../public/delete1.png"
          @click="handleDelete(item.configId)"
          style="margin-left: 12px; margin-right: 18px; margin-top: 13px"
          title="删除"
        />
        <!-- 细胞编辑 -->
        <img
          src="../../public/edit1.png"
          @click="handleEdit(item)"
          title="编辑"
        />
      </el-col>
    </el-row>
    <!-- 细胞编辑弹出框 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogFormVisible"
      class="dialog"
      :close-on-click-modal="false"
    >
      <el-input class="dialogInput" v-model="editParameterName"></el-input>
      <el-input v-model="editParameterValue"></el-input>
      <div class="checkbox-bar">
        <el-checkbox
          label="是否包括23bp序列结果"
          class="result"
          v-model="editUse23BP"
          v-show="false"
        ></el-checkbox>
        <el-checkbox
          label="设置为默认"
          class="default"
          v-model="editIsDefault"
        ></el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="paramEdit()">确 定 </el-button>
      </div>
    </el-dialog>
    <div class="cell">
      <!-- 酶 -->
      <span style="margin: 20px 20px 0px 30px">Enzyme</span>
      <!-- 酶添加 -->
      <img
        class="addE"
        src="../../public/add1.png"
        @click="handleAdd(3, 2)"
        title="添加"
      />
      <!-- 酶添加弹出框 -->
      <el-dialog
        title="添加"
        :visible.sync="dialogFormVisibleAddEnzyme"
        class="dialog"
        :close-on-click-modal="false"
      >
        <el-input
          v-model="inputEnzymeAdd1"
          placeholder="Parameter name"
        ></el-input>
        <el-input
          v-model="inputEnzymeAdd2"
          placeholder="Parameter value"
        ></el-input>
        <div class="checkbox-bar">
          <el-checkbox
            label="是否包括23bp序列结果"
            class="result"
            v-model="typeEnzymeAdd1"
            v-show="false"
          ></el-checkbox>
          <el-checkbox
            label="设置为默认"
            class="default"
            v-model="typeEnzymeAdd2"
          ></el-checkbox>
        </div>
        <div slot="footer" class="dialog-footer-add">
          <el-button @click="dialogFormVisibleAddEnzyme = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="getParamAdd()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-row
      :gutter="20"
      style="
        margin-left: 67px;
        margin-right: 0px;
        margin-bottom: 5px;
        margin-top: 10px;
      "
    >
      <el-col :span="8"> Parameter name </el-col>
      <el-col :span="8"> Parameter value </el-col>
    </el-row>
    <el-row
      :gutter="20"
      v-for="item in CasTargetEnzymeTableData"
      :key="item.configId"
      style="margin-left: 67px; margin-right: 0px; margin-bottom: 20px"
    >
      <el-col :span="8">
        <el-input :disabled="true" v-model="item.name"></el-input>
      </el-col>
      <el-col :span="8">
        <el-input :disabled="true" v-model="item.value"></el-input>
      </el-col>
      <el-col :span="8">
        <!-- 酶删除 -->
        <img
          src="../../public/delete1.png"
          @click="handleDelete(item.configId)"
          style="margin-left: 12px; margin-right: 18px; margin-top: 13px"
          title="删除"
        />
        <!-- 酶编辑 -->
        <img
          src="../../public/edit1.png"
          @click="handleEdit(item)"
          title="编辑"
        />
      </el-col>
    </el-row>
    <!-- 酶编辑弹出框 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogFormVisibleEnzyme"
      class="dialog"
      :close-on-click-modal="false"
    >
      <el-input v-model="editEnzymeName"></el-input>
      <el-input v-model="editEnzymeValue"></el-input>
      <div class="checkbox-bar">
        <el-checkbox
          label="是否包括23bp序列结果"
          class="result"
          v-model="typeEnzymeEdit1"
          v-show="false"
        ></el-checkbox>
        <el-checkbox
          label="设置为默认"
          v-model="typeEnzymeEdit2"
          :disabled="hasDefault"
        ></el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEnzyme = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisibleEnzyme = false"
          >确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { instance, ajax } from "@/api/index";
export default {
  name: "CasTarget",
  data() {
    return {
      addParameterName: "",
      addParameterValue: "",
      addUse23BP: false,
      addIsDefault: false,
      addType: "",
      addGroup: "",

      configId: "",
      editParameterName: "",
      editParameterValue: "",
      editUse23BP: false,
      editIsDefault: false,
      editType: "",
      editGroup: "",

      typeCellEdit1: false,
      typeCellEdit2: false,
      typeEnzymeAdd1: false,
      typeEnzymeAdd2: false,
      typeEnzymeEdit1: false,
      typeEnzymeEdit2: false,
      dialogFormVisibleAdd: false,
      dialogFormVisibleAddEnzyme: false,
      dialogFormVisible: false,
      dialogFormVisibleEnzyme: false,
      editCellId: "",
      editCellName: "",
      editCellValue: "",
      editEnzymeId: "",
      editEnzymeName: "",
      editEnzymeValue: "",

      input3: "",
      input4: "",
      inputEnzymeAdd1: "",
      inputEnzymeAdd2: "",
      // CasTargetCellTableData: [],
      // CasTargetEnzymeTableData: [],
      configParams: [],
      hasDefault: false,
    };
  },
  created() {
    this.getParamList();
  },

  computed: {
    OnTargetCellTableData() {
      var r = this.configParams.filter((a) => a.group === 1 && a.type === 1);
      return r;
    },
    OnTargetEnzymeTableData() {
      var r = this.configParams.filter((a) => a.group === 1 && a.type === 2);
      return r;
    },
    OffTargetCellTableData() {
      var r = this.configParams.filter((a) => a.group === 2 && a.type === 1);
      return r;
    },
    OffTargetEnzymeTableData() {
      var r = this.configParams.filter((a) => a.group === 2 && a.type === 2);
      return r;
    },
    CasTargetCellTableData() {
      var r = this.configParams.filter((a) => a.group === 3 && a.type === 1);
      return r;
    },
    CasTargetEnzymeTableData() {
      var r = this.configParams.filter((a) => a.group === 3 && a.type === 2);
      return r;
    },
  },
  methods: {
    // 列表获取
    getParamList() {
      ajax("/Manager/ParamsConfigCollection", {}, "GET")
        .then((res) => {
          var data = res.data;
          this.configParams = data.result.records;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: "请求失败，请重试!",
          });
        });
    },
    // 添加
    paramAdd() {
      ajax(
        "/Manager/AddParamsConfig",
        {
          name: this.addParameterName,
          value: this.addParameterValue,
          use23BP: this.addUse23BP,
          isDefault: this.addIsDefault,
          group: this.addGroup,
          type: this.addType,
        },
        "POST"
      )
        .then((res) => {
          if (res.data.success) {
            this.operationSuccess();
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: "请求失败，请重试!",
          });
        });
    },
    // 编辑
    paramEdit() {
      ajax(
        "/Manager/EditParamsConfig",
        {
          configId: this.configId,
          name: this.editParameterName,
          value: this.editParameterValue,
          use23BP: this.editUse23BP,
          isDefault: this.editIsDefault,
          group: this.editGroup,
          type: this.editType,
        },
        "POST"
      )
        .then((res) => {
          if (res.data.success) {
            this.operationSuccess();
          }
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: "请求失败，请重试!",
          });
        });
    },
    //elementUI中单元格 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return { borderBottom: "none" };
    },

    /**
     * 细胞添加窗口控制
     * @param group 1：OnTarget 2：OffTarget 3：Cas9DSBOutcome
     * @param type 1：Cell 2：Enzyme
     */
    handleAdd(group, type) {
      this.dialogFormVisibleAdd = true;
      this.addGroup = group;
      this.addType = type;
    },

    operationSuccess() {
      this.getParamList();
      this.dialogFormVisible = false;
      this.dialogFormVisibleAdd = false;
      this.$message({
        type: "success",
        message: "保存成功!",
      });
    },
    //细胞编辑窗口控制
    handleEdit(item) {
      this.dialogFormVisible = true;
      this.editParameterName = item.name;
      this.editParameterValue = item.value;
      this.editUse23BP = item.use23BP;
      this.editIsDefault = item.isDefault;
      this.configId = item.configId;
      this.editGroup = item.group;
      this.editType = item.type;
      this.hasDefault = item.isDefault;
    },

    //删除弹出框
    handleDelete(configId) {
      this.$confirm("是否确认删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "delete",
        type: "warning",
        closeOnClickModal: false,
      })
        //点击确定触发
        .then(() => {
          ajax("/Manager/DeleteParamsConfig", { configId: configId }, "delete")
            .then((response) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getParamList();
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        })
        // 点击取消触发
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.param span {
  display: block;
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 0px;
  color: #333333;
  opacity: 1;
  margin-left: 30px;
  margin-top: 40px;
}
/* cas-target的div */

.cell {
  display: flex;
}

.add {
  width: 18px;
  height: 18px;
  margin-top: 32px;
  margin-left: 10px;
}
.addE {
  width: 18px;
  height: 18px;
  margin-top: 13px;
  margin-left: -10px;
}
.el-table__row td {
  border: none;
}

.dialog >>> .el-dialog__header {
  width: 100%;
  padding: 0px;
  text-align: center;
  position: relative;
}
.dialog >>> .el-dialog__title {
  width: 36px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 0px;
  color: #1fa1a0;
  opacity: 1;
  position: absolute;
  top: 40px;
  left: 180px;
}
.dialogInput {
  margin-top: 50px;
}
.dialog >>> .el-dialog__body {
  padding: 0px;
}
.dialog >>> input {
  margin-left: 30px;
  margin-top: 30px;
  width: 340px;
  height: 45px;
  background: #f3f3f3;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 5px;
}
.dialog >>> .el-checkbox__label {
  font-size: 12px;
}
.dialog >>> .el-input:first-child {
  margin-top: 50px;
}
.checkbox-bar {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  padding: 0px 30px;
}

.result {
  font-family: PingFang SC;
  color: #1fa1a0;
}

.default {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #a1a1a1;
  opacity: 1;
}
.dialog >>> .el-dialog {
  width: 400px;
  height: 336px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 10px;
}
.dialog-footer {
  display: flex;
}
.dialog .el-button {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 20px;
  width: 156px;
  height: 45px;
  opacity: 1;
  border-radius: 5px;
  border: none;
}
.dialog .el-button--primary {
  margin-left: 0px;
  width: 156px;
  height: 45px;
  background: #1fa1a0;
  opacity: 1;
  border-radius: 5px;
  margin-left: 28px;
}
.dialog .el-button--default {
  width: 156px;
  height: 45px;
  background: #b8b8b8;
  opacity: 1;
  border-radius: 5px;
  margin-left: 10px;
}
.dialog >>> .el-icon-close:before {
  content: none;
}
.dialog >>> .el-checkbox__original {
  display: none;
}
img {
  width: 22px;
  height: 22px;
}
</style>
