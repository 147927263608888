<template>
  <div
    class="portrait"
    @mouseover="selectOver"
    @mouseleave="selectLeave"
    v-if="portraitShow && userName != ''"
  >
    <img class="icon" src="../assets/头像.png" />
    <p class="portrait-p">{{ userName }}</p>
    <div
      class="suspension"
      v-if="selectShow"
      @mouseover="selectOver"
      @mouseleave="selectLeave"
    >
      <span></span>
      <p @click="exitLogin">Sign Off</p>
    </div>
  </div>
</template> 

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      selectShow: false,
      portraitShow: true,
      userName: "",
    };
  },
  watch: {
    $route(to, from) {
      if (Cookies.get("userName")) {
        this.userName = Cookies.get("userName");
      }
    },
  },
  mounted() {
    if (Cookies.get("userName")) {
      this.userName = Cookies.get("userName");
    }
  },
  methods: {
    selectOver() {
      this.selectShow = true;
    },
    selectLeave() {
      this.selectShow = false;
    },
    exitLogin() {
      Cookies.remove("userName");
      Cookies.remove("email");
      Cookies.remove("token");
      this.$router.go();
    },
  },
};
</script>

<style scoped>
.portrait {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 0 12px;
  justify-content: flex-end;
  height: 60px;
}
.portrait .suspension {
  position: absolute;
  top: 56px;
  width: 89px;
  height: 29px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 5px;
}
.portrait .suspension span {
  position: absolute;
  top: -10px;
  right: 37px;
  display: block;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ffffff;
  opacity: 1;
}
.portrait .suspension div {
  width: 96px;
  height: 0px;
  border: 1px solid #cbcbcb;
  opacity: 1;
  margin: 0 auto;
}
.portrait .suspension p {
  font-size: 14px;
  margin-top: 5px;
  color: #2a2a2a;
  font-weight: bold;
  opacity: 1;
  text-align: center;
  cursor: pointer;
}
.portrait .portrait-p {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  margin-left: 10px;
  white-space: nowrap;
}
.portrait img {
  width: 35px;
  height: 36px;
}
</style>