import Vue from 'vue'
import App from './App.vue'
import "../mock/index"
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import md5 from 'js-md5'
import 'element-ui/lib/theme-chalk/index.css';
import Cookies from "js-cookie";
import * as echarts from 'echarts'
 
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$md5 = md5
Vue.use(ElementUI)

// 路由判断登录 根据路由配置文件的参数
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    if (Cookies.get("token")) {
      // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
