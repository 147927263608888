<template>
  <div>
    <!--头部区域-->
    <Header></Header>
    <el-container class="contain">
      <el-aside style="width: auto;margin-top: 10px; border: 1px solid #cecece;"> <Left :isCollapse="isCollapse"></Left></el-aside>
      <el-main>
        <Main :toggleCollapse="toggleCollapse" :isCollapse="isCollapse">
          <router-view></router-view> </Main
      ></el-main>
      <!--左侧菜单-->

      <!--右侧主体-->
    </el-container>
  </div>
</template>


<script>
import Header from "./Header.vue";
import Left from "./Left.vue";
import Main from "./Main.vue";
export default {
  name: "LayOut",
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    // 修改isCollapse状态的方法
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
  components: {
    Header,
    Left,
    Main,
  },
};
</script>

<style  scoped>
.contain{
  width: 1200px;
  height: 100%;
  min-height: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.el-main{
  min-height: 1000px;
  padding:0;
}

</style>