<template>
  <div class="wrapper">
    <el-input class="search" clearable v-model="searchContent" placeholder="请输入用户名或邮箱进行查询" @input="searchChange">
    </el-input>
    <!-- <el-button @click="searchChange">搜索</el-button> -->
    <div class="table">
      <el-table :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" ref="Table"
        @filter-change="filterChange" style="width: 100%; border: 1px solid #dbdbdb" @sort-change="sortChange">
        <el-table-column prop="name" label="用户名" column-key="name" :filters="filters" width="110px">
        </el-table-column>
        <el-table-column prop="email" label="邮箱" column-key="email" :filters="mailboxFilters" width="270px">
        </el-table-column>
        <el-table-column prop="registerTime" label="注册时间" sortable="custom" width="180px">
        </el-table-column>
        <el-table-column prop="lastTime" label="最后访问时间" width="180px" sortable="custom">
        </el-table-column>
        <el-table-column prop="status" label="激活状态" width="100px" column-key="status" :filters="statusFilters">
        </el-table-column>
        <el-table-column column-key="isInternalUser" label="权限" :filters="permissionFilters">
          <template slot-scope="scope">
            <el-select v-model="scope.row.isInternalUser" @click.native="get(scope.row)" @change="changeRole(scope.row)">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-button type="button" @click="jumpFirstPage" class="my-btn">首页</el-button>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
        layout="prev, pager, next" :total="tableData.length" :page-size="pageSize" :current-page.sync="currentPage">
      </el-pagination>
      <el-button type="button" @click="jumpLastPage" class="my-btn">尾页</el-button>
    </div>
  </div>
</template>

<script>
import { instance, ajax } from "@/api/index";
export default {
  data() {
    return {
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据条数
      tableData: [
        {
          name: "",
          email: "",
          registerTime: "",
          lastTime: "",
          isInternalUser: ""
        },
      ],
      options: [
        {
          value: "1",
          label: "内部",
        },
        {
          value: "0",
          label: "外部",
        },
      ],
      value: "",
      filters: [],
      mailboxFilters: [],
      permissionFilters: [],
      statusFilters: [],
      group: [],
      searchContent: ""
    };
  },
  created() {
    this.getList();
  },
  methods: {
    searchChange() {
      this.$refs.Table.clearFilter();
      this.tableData = this.group;
      // console.log(this.searchContent);
      this.tableData = this.group.filter(item => {
        return item.name.indexOf(this.searchContent) > -1 || item.email.indexOf(this.searchContent) > -1
      })
    },
    filterChange(filters) {
      this.searchContent = ""
      this.$refs.Table.clearFilter();
      this.tableData = this.group;
      //console.log(filters, Object.keys(filters), Object.values(filters));
      if (Object.values(filters)[0].length > 0) {
        var filterArr = [];
        for (var i in this.tableData) {
          if (Object.values(filters)[0].indexOf(this.tableData[i][Object.keys(filters)]) > -1)
            filterArr.push(this.tableData[i]);
        }
        // console.log(filterArr);
        this.tableData = filterArr;
      } else {
        // console.log(this.group)
        this.tableData = this.group;
      }
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    getFilters() {
      // console.log(this.tableData);
      this.filters = this.tableData.map((obj) => ({
        text: obj.name,
        value: obj.name,
      }));
      this.mailboxFilters = this.tableData.map((obj) => ({
        text: obj.email,
        value: obj.email,
      }));
      this.permissionFilters = [{ text: "内部", value: "内部" }, { text: "外部", value: "外部" }]
      this.statusFilters = [{ text: "已激活", value: "已激活" }, { text: "未激活", value: "未激活" }]
      // console.log(this.filters);
    },

    sortChange(column) {
      // console.log(column);
      this.currentPage = 1;
      var order = column.order;
      var prop = column.prop;
      this.tableData.sort((a, b) => {
        if (order == "ascending") {
          return (b[prop] != '' && b[prop] != null) - (a[prop] != '' && a[prop] != null) || new Date(a[prop]) - new Date(b[prop]);
        } else {
          return (b[prop] != '' && b[prop] != null) - (a[prop] != '' && a[prop] != null) || new Date(b[prop]) - new Date(a[prop]);
        }
      });
    },
    get(row) {
      // console.log(row,"111");
      sessionStorage.setItem("obj", JSON.stringify(row.isInternalUser));
    },
    changeRole(row) {
      // console.log(row)
      this.$confirm("是否确认更改?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "delete",
        type: "warning",
        closeOnClickModal: false,
      })
        //点击确定触发
        .then(() => {
          ajax(
            "/Manager/ChangeUserPermissions",
            { UserName: row.name, Permissions: Number(row.isInternalUser) },
            "GET"
          )
            .then((res) => {
              console.log(res);
              if (res.data.result == true) {
                this.$message({
                  type: "success",
                  message: "更改成功!",
                });
              } else {
                this.$message({
                  type: "info",
                  message: "更改失败!",
                });
              }
            })
            .catch((err) => {
              this.$message({
                type: "info",
                message: "更改失败!",
              });
            });
        })
        // 点击取消触发
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更改",
          });
          // 取消时把原来存储的值赋给现在的对象
          let obj = sessionStorage.getItem("obj");
          // console.log(obj);
          if (obj !== null) {
            row.isInternalUser = JSON.parse(obj);
          } else {
            console.log("No data in sessionStorage");
          }
        });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getList() {
      ajax(
        "/Manager/UserCollection",
        {},
        "GET"
      )
        .then((res) => {
          // console.log(res);
          let rs = res.data.result;
          var records = rs.records;
          this.tableData = records.map((a) => {
            var registerTime = new Date(a.registerOn);
            var lastTime = new Date(a.lastAccessOn);
            return {
              name: a.userName,
              email: a.email,
              isInternalUser: a.isInternalUser == 1 ? "内部" : "外部",
              status: a.emailConfirm == 1 ? "已激活" : "未激活",
              registerTime: this.formatDate(registerTime),
              lastTime:
                lastTime.getFullYear() != "1970"
                  ? this.formatDate(lastTime)
                  : "",
            };
          });
          this.group = JSON.parse(JSON.stringify(this.tableData));
          this.getFilters();
        })
        .catch((error) => {
          // console.log(error);
          this.$message({
            type: "error",
            message: "请求失败，请重试!",
          });
        });
    },
    jumpFirstPage() {
      this.currentPage = 1;
    },
    jumpLastPage() {
      var i = this.tableData.length % this.pageSize > 0 ? 1 : 0;
      this.currentPage = ~~(this.tableData.length / this.pageSize + i);
    },
  },
};
</script>

<style scoped>
.wrapper {
  margin-left: 10px;
  margin-top: 10px;
}

.page {
  display: flex;
  margin-top: 42px;
  justify-content: center;
  align-items: center;
}

.wrapper>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1fa1a0;
}

.search {
  width: 50%;
  margin-bottom: 2px;
}

.my-btn {
  padding-right: 0px;
  padding-left: 0px;
  border: none;
  background: none;
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #333333;
  opacity: 1;
}

.wrapper>>>.cell {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}

.dialog>>>.el-dialog__header {
  width: 100%;
  padding: 0px;
  text-align: center;
  position: relative;
}

.dialog>>>.el-dialog__title {
  width: 36px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 0px;
  color: #1fa1a0;
  opacity: 1;
  position: absolute;
  top: 40px;
  left: 180px;
}

.dialogInput {
  margin-top: 50px;
}

.dialog>>>.el-dialog__body {
  padding: 0px;
}

.dialog>>>input {
  margin-left: 30px;
  margin-top: 30px;
  width: 340px;
  height: 45px;
  background: #f3f3f3;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 5px;
}

.dialog>>>.el-checkbox__label {
  font-size: 12px;
}

.dialog>>>.el-input:first-child {
  margin-top: 50px;
}

.default {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #a1a1a1;
  opacity: 1;
}

.dialog>>>.el-dialog {
  width: 400px;
  height: 336px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 10px;
}

.dialog-footer {
  display: flex;
}

.dialog .el-button {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 20px;
  width: 156px;
  height: 45px;
  opacity: 1;
  border-radius: 5px;
  border: none;
}

.dialog .el-button--primary {
  margin-left: 0px;
  width: 156px;
  height: 45px;
  background: #1fa1a0;
  opacity: 1;
  border-radius: 5px;
  margin-left: 28px;
}

.dialog .el-button--default {
  width: 156px;
  height: 45px;
  background: #b8b8b8;
  opacity: 1;
  border-radius: 5px;
  margin-left: 10px;
}

.dialog>>>.el-icon-close:before {
  content: none;
}

.dialog>>>.el-checkbox__original {
  display: none;
}

img {
  width: 22px;
  height: 22px;
}
</style>
