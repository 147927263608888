<template>
    <el-main>
        <span class="icon iconfont icon-hamburger" @click="toggleAsideBar" :class="{'is-collapse':isCollapse}"></span>
        <router-view></router-view>
    </el-main>
</template>

<script>
    export default {
        name: "AppMain.vue",
        props:{
            toggleCollapse:{
                type:Function
            },
            isCollapse:{
                type:Boolean
            }
        },
        methods:{
            toggleAsideBar(){
                this.toggleCollapse();
            }
        }
    }
</script>

<style scoped>
</style>