import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Layout from '../views/Layout.vue'
import Log from '../views/Log.vue'
import User from '../views/user.vue'
import Param from '../views/Param.vue'
import Manager from '../views/Manager.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

let routes = [
    { path: "/", redirect: "/login", },
    { path: "/login", name: "login", component: Login },
    {
        path: "/log",
        component: Layout,
        children: [
            { path: "", name: "log", component: Log }
        ],
        meta: {
            requireAuth: true, // 判断是否需要登录
        },
    },
    {
        path: "/user",
        component: Layout,
        children: [
            { path: "", name: "user", component: User },
        ],
        meta: {
            requireAuth: true, // 判断是否需要登录
        },
    },
    {
        path: "/param",
        component: Layout,
        children: [
            { path: "", name: "param", component: Param },
        ],
        meta: {
            requireAuth: true, // 判断是否需要登录
        },
    },
    {
        path: "/manager",
        component: Layout,
        children: [
            { path: '', name: 'manager', component: Manager },
        ],
        meta: {
            requireAuth: true, // 判断是否需要登录
        },
    },
    {
        path: "/home",
        component: Layout,
        children: [
            { path: '', name: 'home', component: Home },
        ],
        meta: {
            requireAuth: true, // 判断是否需要登录
        },
    },
    {
        // 没有没有匹配到的路由，则重定向到首页
        path: "*",
        meta: {
            default_db_type: "0"
        },
        redirect: "/"
    }

]
let router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// 解决vue-router重复点击报错  push方法
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
// replace方法
const originalrePlace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
    return originalrePlace.call(this, location).catch(err => err)
}

// 全局守卫
// router.beforeEach((to,from,next)=>{
//     if(to.path === "/login"){
//         return next();
//     }
//     let token = window.sessionStorage.getItem("token");
//     if(!token){
//         return next("/login");
//     }
//     next();
// })

export default router;
































