var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('el-row',{staticClass:"tac"},[_c('el-col',[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":_vm.$route.path,"router":""},on:{"select":_vm.getIndex}},[_c('el-menu-item',{attrs:{"index":"/home"},on:{"click":function($event){return _vm.dealNavLink(_vm.index)}}},[_c('img',{staticClass:"home1",staticStyle:{"width":"22px","height":"22px","margin-right":"0px"},attrs:{"src":_vm.index === '/home'
                ? require('../../public/homeselected.png')
                : require('../../public/home.png')}}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("首页")])]),_c('el-menu-item',{attrs:{"index":"/log"},on:{"click":function($event){return _vm.dealNavLink(_vm.index)}}},[_c('img',{staticClass:"log1",attrs:{"src":_vm.index === '/log'
                ? require('../../public/logselected1.png')
                : require('../../public/log1.png')}}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("日志管理")])]),_c('el-menu-item',{attrs:{"index":"/user"},on:{"click":function($event){return _vm.dealNavLink(_vm.index)}}},[_c('img',{staticClass:"user1",attrs:{"src":_vm.index === '/user'
                ? require('../../public/userselected1.png')
                : require('../../public/user1.png')}}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("用户管理")])]),_c('el-menu-item',{attrs:{"index":"/param"},on:{"click":function($event){return _vm.dealNavLink(_vm.index)}}},[_c('img',{staticClass:"param1",attrs:{"src":_vm.index === '/param'
                ? require('../../public/paramselected1.png')
                : require('../../public/param1.png')}}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("参数设置")])]),_c('el-menu-item',{attrs:{"index":"/manager"},on:{"click":function($event){return _vm.dealNavLink(_vm.index)}}},[_c('img',{staticClass:"manager1",attrs:{"src":_vm.index === '/manager'
                ? require('../../public/managerselected1.png')
                : require('../../public/manager1.png')}}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("管理员")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }