<template>
  <div class="left">
    <el-row class="tac">
      <el-col>
        <el-menu
          @select="getIndex"
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          router
        >
        <el-menu-item index="/home" @click="dealNavLink(index)">
            <img
            style="width: 22px; height: 22px;margin-right: 0px;"
              :src="
                index === '/home'
                  ? require('../../public/homeselected.png')
                  : require('../../public/home.png')
              "
              class="home1"
            />
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="/log" @click="dealNavLink(index)">
            <img
              :src="
                index === '/log'
                  ? require('../../public/logselected1.png')
                  : require('../../public/log1.png')
              "
              class="log1"
            />
            <span slot="title">日志管理</span>
          </el-menu-item>
          <el-menu-item index="/user" @click="dealNavLink(index)">
            <img
              :src="
                index === '/user'
                  ? require('../../public/userselected1.png')
                  : require('../../public/user1.png')
              "
              class="user1"
            />
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item index="/param" @click="dealNavLink(index)">
            <img
              :src="
                index === '/param'
                  ? require('../../public/paramselected1.png')
                  : require('../../public/param1.png')
              "
              class="param1"
            />
            <span slot="title">参数设置</span>
          </el-menu-item>
          <el-menu-item index="/manager" @click="dealNavLink(index)">
            <img
              :src="
                index === '/manager'
                  ? require('../../public/managerselected1.png')
                  : require('../../public/manager1.png')
              "
              class="manager1"
            />
            <span slot="title">管理员</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: this.$route.path,
    };
  },
  mounted() {
    this.dealNavLink(this.index);
  },
  methods: {
    dealNavLink(index) {
      this.$router.push(index);
    },
    getIndex(index) {
      this.index = index;
      // console.log(index);
    },
  },
};
</script>

<style scoped>
.left {
  width: 236px !important;
  height: 100%;
  min-height: 1000px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  float: left;
}

.left span {
  width: 68px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #b8b8b8;
  opacity: 1;
  margin-left: 15px;
}

.el-menu-item.is-active ::after {
  content: "";
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 100%;
  background: #1fa1a0;
}
.el-menu-item.is-active span {
  color: #1fa1a0 !important;
}
.el-menu-item.is-active {
  width: 100%;
  background: #a9d9ec;
}
.el-menu {
  border: 0px;
}
.el-menu-item {
  position: relative;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  width: 22px;
  height: 22px;
}
.el-menu-item.is-active img {
  width: 23px;
  height: 22px;
}

</style>