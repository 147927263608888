<template>
  <div class="wrapper">
    <div class="table">
      <el-table :data="tableData" style="border: 1px solid #dbdbdb">
        <el-table-column prop="name" label="用户名" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="interface" label="访问接口" width="350">
        </el-table-column>

        <el-table-column prop="query" label="Query参数" width="300">
          <template slot-scope="slotData">
            <el-tooltip effect="light" placement="top-start">
              <div slot="content">
                <div class="queryParaTip">{{ slotData.row.query }}</div>
              </div>
              <p class="queryPara">{{ slotData.row.query }}</p>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="IP" label="访问IP"> </el-table-column>
        <el-table-column prop="address" label="地区" :show-overflow-tooltip="true" width="100">
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-button type="button" @click="jumpFirstPage" class="my-btn">首页</el-button>
      <el-pagination @current-change="currentChange" background layout="prev, pager, next" :total="total"
        :page-size="Size" :current-page.sync="Index">
      </el-pagination>
      <el-button type="button" @click="jumpLastPage" class="my-btn">尾页</el-button>
    </div>
  </div>
</template>

<script>
import { instance, ajax } from "@/api/index";

export default {
  data() {
    return {
      Index: 0,
      Size: 10,
      total: 0,
      tableData: [
        {
          name: "",
          interface: "",
          query: "",
          IP: "",
          address: "",
        },
      ],
   
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
   
    getList() {
      ajax(
        "/Manager/AccessLogCollection",
        { Index: this.Index, Size: this.Size },
        "GET"
      )
        .then((res) => {
          // console.log(this.Index)
          // console.log("res",res)
          var records = res.data.result.records;
          this.tableData = records.map((a) => {
            return {
              name: a.accessUserName,
              interface: a.accessUri,
              query: a.accessUriParams,
              IP: a.accessIP,
              address: a.accessIPArea,
            };
          });
          this.total = res.data.result.total;
          // this.drawBar(allDays);
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: "请求失败，请重试!",
          });
        });
    },

    currentChange(index) {
      // console.log(index)
      // this.Index=data
      this.getList();
    },

    jumpFirstPage() {
      this.Index = 1;
      this.getList();
    },
    jumpLastPage() {
      var i = this.total % this.Size > 0 ? 1 : 0;
      this.Index = ~~(this.total / this.Size + i);
      this.getList();
    },
  },
};
</script>

<style scoped>
.wrapper {
  margin-left: 10px;
  margin-top: 10px;
}


p {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  opacity: 1;
}

.page {
  display: flex;
  margin-top: 42px;
  justify-content: center;
  align-items: center;
}

.wrapper>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1fa1a0;
}

.my-btn {
  padding-right: 0px;
  padding-left: 0px;
  border: none;
  background: none;
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #333333;
  opacity: 1;
}

.wrapper>>>.cell {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}

.queryParaTip {
  max-width: 600px;
  max-height: 400px;
  overflow: auto;
}

.queryPara {
  white-space: nowrap;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
</style>
