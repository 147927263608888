import Mock from 'mockjs'

Mock.mock('/Manager/ParamsConfigCollection', 'get', (options) => {
    console.log("mock ParamsConfigCollection")
    return {
        success: true,
        errorCode: 200,
        errorMsg: "",
        result: {
            records: [{
                    configId: 1,
                    name: "K562",
                    value: "K562",
                    isDefault: true,
                    use23BP: true,
                    group: "OnTarget",
                    type: "Cell",
                },
                {
                    configId: 2,
                    name: "jurkat",
                    value: "jurkat",
                    isDefault: true,
                    use23BP: true,
                    group: "OnTarget",
                    type: "Cell",
                },
                {
                    configId: 3,
                    name: "H1",
                    value: "H1",
                    isDefault: true,
                    use23BP: true,
                    group: "OnTarget",
                    type: "Cell",
                },
                {
                    configId: 4,
                    name: "SpCas9",
                    value: "SpCas9",
                    isDefault: true,
                    use23BP: true,
                    group: "OnTarget",
                    type: "Enzyme",
                },
                {
                    configId: 5,
                    name: "K562",
                    value: "K562",
                    isDefault: true,
                    use23BP: true,
                    group: "OffTarget",
                    type: "Cell",
                },
                {
                    configId: 6,
                    name: "SpCas9",
                    value: "SpCas9",
                    isDefault: true,
                    use23BP: true,
                    group: "Cas9DSBOutcome",
                    type: "Enzyme",
                },
                {
                    configId: 7,
                    name: "K562",
                    value: "K562",
                    isDefault: true,
                    use23BP: true,
                    group: "Cas9DSBOutcome",
                    type: "Cell",
                },
                {
                    configId: 8,
                    name: "jurkat",
                    value: "jurkat",
                    isDefault: true,
                    use23BP: true,
                    group: "Cas9DSBOutcome",
                    type: "Cell",
                },
                {
                    configId: 9,
                    name: "K52",
                    value: "K52",
                    isDefault: true,
                    use23BP: true,
                    group: "OffTarget",
                    type: "Enzyme",
                },
            ],
            total: 20
        }
    }

})


Mock.mock('/Manager/AddParamsConfig', 'post', (params) => {

    return {
        success: true,
        errorCode: 200,
        errorMsg: "",
    }

})

Mock.mock('/Manager/EditParamsConfig', 'post', (params) => {

    return {
        success: true,
        errorCode: 200,
        errorMsg: "",
    }

})
Mock.mock('/Manager/Login', 'get', (options) => {

    return {
        Username: "admin",
        Password: 123
    }
})