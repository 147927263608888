<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style>
* {
  list-style: none;
  padding: 0px;
}
body {
  margin: 0;
  width: 100%;
  height: 100%;
  background: #f3f3f3;
}
/* 超出部分隐藏了滚动条 */

/* 当显示dialog的时候 body 默认多出了一个 paading-right */
body {
  padding-right: 0px !important;
}
.el-popup-parent--hidden {
  overflow: initial !important;
}

/* 删除的弹出框样式 */
.delete {
  width: 400px !important;
  height: 279px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 10px;
  margin-top: -14.5vh;
}
/* 取消和确定按钮的样式 */
.delete .el-button {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
  width: 156px;
  height: 45px;
  background: #b8b8b8;
  opacity: 1;
  border-radius: 5px;
  margin-right: 16px;
  border: none;
  margin-top: 165px;
}
/* 确定按钮的样式 */
.delete .el-button--primary {
  margin-left: 0px;
  background: #1FA1A0;
  margin-left: 28px;
}
/* 解决按钮点击或触摸变色问题 */
.delete .el-button--primary:focus:not(.focusing) {
  background: #1FA1A0;
}
.delete .el-button:hover {
  background: #b8b8b8;
  color: #ffffff;
}
/* 删除图标的样式 */
.el-message-box__status.el-icon-warning {
  color: #1FA1A0 !important;
  margin-top: 55px;
  font-size: 122px !important;
  margin-left: 121px;
}
.el-message-box__message {
  text-align: center;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 0px;
  color: #1FA1A0;
  opacity: 1;
  position: absolute;
  top: 130px;
}
.el-message-box__message {
  width: 100%;
  padding-left: 0px !important;
}
.delete .el-icon-close:before {
  content: none;
}
</style>


